'use client'

import React from "react";
import { Skeleton } from "antd-mobile";
import style from "./Loading.module.css";

export default function MySkeleton() {
  return (
    <>
      <Skeleton.Title className={style.title2_skeleton} animated />
      <div  className={style.contentdiv1}>
      <div className={style.contentdiv}>
      <Skeleton animated className={style.con1_skeleton} />
      <Skeleton animated className={style.con2_skeleton} />
      <Skeleton animated className={style.con2_skeleton} />
      </div>
      <div className={style.contentdiv}>

      <Skeleton animated className={style.con2_skeleton} />
      <Skeleton animated className={style.con2_skeleton} />
      <Skeleton animated className={style.con2_skeleton} />
      </div>

      </div>
    </>
  );
}
