"use client";

import { useEffect, useState } from "react";
import { getFocus } from "@/api/home.js";
import SwiperNew from "@/components/SwiperNew";
import s from "./Swiper.module.css";

const SwiperDom = (props) => {
 const [swiperData, setSwiperData] = useState([]);

  useEffect(()=>{
    getFocus({ type: 1 }).then(res=>{
      setSwiperData(res || []);
    })
  }, [])
  
  return (
    <div className={s.swiper}>
      <SwiperNew datas={swiperData} swiperClass={s.swiperClass} reportId='a0040004300000600000' />
    </div>
  );
};

export default SwiperDom;
