"use client";

import Cookie from "@/utils/Cookie";
import useLocation from "@/hooks/useLocation";
import useRouter from "@/hooks/useRouter";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { historyFun } from "@/app/selectDom/city/utils";
import { Modal, Toast } from "antd-mobile";
import s from "./index.module.css";

// 弹层，确认是否切换到定位城市
const LocationToCity = (props) => {
  const {
    changePositionCityLoadingAdd,
    callback, // 回调函数
  } = props;

  const router = useRouter();

  // const = useState({})
  const [visible, setVisible] = useState(false);
  const [oldCity, setOldCity] = useState({});

  const [cityInfoLng] = useLocation({ isGetCity: 1 });

  useEffect(() => {
    if (cityInfoLng && cityInfoLng.cityId) {
      checkCityDiff({
        cityId: cityInfoLng.cityId,
        cityCode: cityInfoLng.cityAreaId,
        cityName: cityInfoLng.cityName,
        citySpell: cityInfoLng.citySpell,
      });
    }
  }, [cityInfoLng]);

  // 定位城市和正在访问的城市的一致性检测
  const checkCityDiff = (obj) => {
    const ccity = Cookie.get("city") ? JSON.parse(Cookie.get("city")) : {};
    const localCityId = +ccity?.cityId || "";
    setOldCity(ccity);
    if (+localCityId !== +obj?.cityId) {
      const alertCityDate = localStorage.getItem("alertCityDate");
      const today = moment().format("YYYYMMDD");
      if (alertCityDate != today) {
        // 每天只提醒一次
        setVisible(true);
      }
    } else {
      // 城市id一样， 也从新获取首页数据，这次是带经纬度的获取哦
      // console.log('刷新首页数据--但不按经纬度城市刷新，所以不传参数')
      callback && callback(); // 刷新首页数据--定位城市和所在城市相同才会返回距门店的距离
    }
  };

  const confirmFun = async () => {
    cancleFun(1);
    // Cookie.remove("storeId"); // 删除storeId，城市变化了，从新获取
    // Cookie.set('city',  JSON.stringify({
    //   cityId: cityInfoLng.cityId,
    //   cityCode: cityInfoLng.cityAreaId,
    //   cityName: cityInfoLng.cityName,
    //   citySpell: cityInfoLng.citySpell
    // }));
    changePositionCityLoadingAdd();

    historyFun(
      { ...oldCity, cityAreaId: oldCity.cityCode },
      { ...cityInfoLng, cityAreaId: cityInfoLng.cityCode }
    );
    router.push("/?city=" + cityInfoLng.citySpell);
    // setTimeout(() => {
    //   callback && callback(cityInfoLng);
    // }, 10)
  };

  const cancleFun = (type) => {
    setVisible(false);

    const today = moment().format("YYYYMMDD");
    localStorage.setItem("alertCityDate", today);
  };

  if (typeof document === "undefined") return;

  return (
    <Modal
      visible={visible}
      getContainer={document?.body}
      style={{ "--border-radius": "0" }}
      content={
        <div>
          <div className={s.content}>
            您当前定位城市为{cityInfoLng.cityName || ""}, 是否切换？
          </div>
          <div className={s.btncontent}>
            <div
              className={`${s.btn} ${s.btn1}`}
              onClick={() => {
                cancleFun();
              }}
            >
              暂不
            </div>
            <div
              className={`${s.btn} ${s.btn2}`}
              onClick={() => {
                confirmFun();
              }}
            >
              确认
            </div>
          </div>
        </div>
      }
    ></Modal>
  );
};

export default LocationToCity;
