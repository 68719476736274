'use client'

import React from "react";
import { Skeleton } from "antd-mobile";
import style from "./Loading.module.css";

export default function MySkeleton() {
  return (
    <>
      <Skeleton.Title className={style.top_skeleton} animated />
      <Skeleton.Title className={style.header_skeleton} animated />
      <Skeleton animated className={style.banner_skeleton} />
      <Skeleton animated className={style.info_skeleton} />
    </>
  );
}
