// 根据百度地图获取经纬度、定位的城市信息
"use client";

import Cookie from "@/utils/Cookie";
import { useEffect, useState, useContext } from "react";
import { getcityinfobymapinfo, getiplocation } from "@/api/home";
import { useGlobal } from "@/components/Global";

const useLocation = (props) => {
  const {
    isGetCity = 1, // 是否获取定位城市信息  1:获取 0:不获取， 默认是1
    isAuto = 1, // 页面加载完毕完毕就自动获取坐标信息 1:获取 0:不获取， 默认是1
  } = props;

  const [cityInfoLng, setCityInfoLng] = useState({});
  let count2 = 0; // 循环最多十次
  const [sessionLocationCityLoading, setSessionLocationCityLoading] = useGlobal(["sessionLocationCityLoading"]);


  useEffect(() => {
    if (isAuto) getZB(); // 获取坐标
  }, []);

  // 获取浏览器-坐标信息
  const getZB = async () => {
    if (count2 > 40) return;

    if (typeof BMapGL === "undefined") {
      setTimeout(() => {
        getZB();
        count2 += 1;
      }, 100);
      return;
    }

    //  if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(function (a){
    //     console.log('aaa',a)
    //   }, function(error) {
    //     // 定位失败，处理错误信息
    //     switch(error.code) {
    //       case error.PERMISSION_DENIED:
    //         console.log("用户拒绝了定位请求");
    //         break;
    //       case error.POSITION_UNAVAILABLE:
    //         console.log("位置信息不可用");
    //         break;
    //       case error.TIMEOUT:
    //         console.log("定位超时");
    //         break;
    //       case error.UNKNOWN_ERROR:
    //         console.log("发生了未知错误");
    //         break;
    //     }
    //   });
    //  }else{
    //   console.log("不支持navigator.geolocation");
    //  }

    let geolocation = new BMapGL.Geolocation();
    count2 = 0;

    setSessionLocationCityLoading(1);

    // 获取之前，先清空这三个cookie
    Cookie.remove("longitude");
    Cookie.remove("latitude");
    Cookie.remove("locationCity");

    geolocation.getCurrentPosition(
      async function (r) {
        // console.log("loading--2=", r);
        // r = {
        //   point : {}
        // }
        // r.point = {
        //   lng : 115.4,
        //   lat : 38.7
        // }
        if (r?.point) {
          Cookie.set("longitude", r?.point?.lng || "");
          Cookie.set("latitude", r?.point?.lat || "");

          if (isGetCity) {

            const { data: ipRs = {} } = await getcityinfobymapinfo({
              longitude: r?.point?.lng,
              latitude: r?.point?.lat,
            });

            // console.log('r?.point',r?.point)
            saveLocationCity({
              cityId: ipRs.cityId,
              cityCode: ipRs.cityAreaId,
              cityName: ipRs.cityName,
              citySpell: ipRs.citySpell,
            });
          }
        } else {
          //城市定位失败， 则从ip获取当前所在城市
          const { data: ipCity = {} } = await getiplocation();
         
          saveLocationCity({
            cityId: ipCity.cityId,
            cityCode: ipCity.cityCode,
            cityName: ipCity.cityName,
            citySpell: ipCity.citySpell,
          });
        }

        setSessionLocationCityLoading(0);
      },
      { enableHighAccuracy: true }
    );
  };

  const saveLocationCity = (obj) => {
    // console.log('设置cookie：',JSON.stringify(obj))

    Cookie.set("locationCity", JSON.stringify(obj));

    setCityInfoLng({
      cityId: obj.cityId,
      cityCode: obj.cityCode,
      cityName: obj.cityName,
      citySpell: obj.citySpell,
    });
  };

  return [cityInfoLng, getZB];
};

export default useLocation;
