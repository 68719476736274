import Image from "next/image";
import useRouter from "@/hooks/useRouter";
import { getLocal } from "@/utils/utils";
import useReport from "@/hooks/useReport";
import s from "./PinPaiDom.module.css";
import more from "@/images/more.png";
import { useSearchParams } from "next/navigation";

const PinPaiDom = (props) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const { homeData = {}, cityData = {} } = props;
  const { reportClick } = useReport();
  const {
    sugarBeans = [],
    shortCutFilter = [],
    mainBrandList = [],
    storeEntry,
  } = homeData;
  const citySpell = getLocal("citySpell");

  const getPam = (curl) => {
    const atd = curl.indexOf("?") > -1 ? "&" : "?";

    let pam = atd + "city=" + cityData.citySpell;
    if (storeEntry?.storeId) {
      pam += "&storeId=" + storeEntry?.storeId;
    }

    return pam;
  };
  const goto = (type, item) => {
    if (type === "pinpai") {
      if (item === "more") {
        const turl =
          typeof window !== "undefined" && window.location.search
            ? `/selectDom/brand${
                window.location.search
              }&returnPath=${encodeURIComponent("/cars")}`
            : `/selectDom/brand?returnPath=${encodeURIComponent("/cars")}`;
        router.push(turl + getPam(turl));
      } else {
        const turl =
          "/cars/?brand=" + item.mainBrandId + "," + item.mainBrandName;
        router.push(turl + getPam(turl));
      }
    } else if (type === "tangdou") {
      let pam = "";
      if (item.url.indexOf(window.location.host) > -1) {
        pam = "city=" + cityData.citySpell;
        if (storeEntry?.storeId) {
          pam += "&storeId=" + storeEntry?.storeId;
        }
      } else if (item.url.indexOf("bangmai") > -1) {
        const fromid =
          searchParams.get("fromid") || sessionStorage.getItem("fromid");
        const innerfromid =
          searchParams.get("innerfromid") ||
          sessionStorage.getItem("innerfromid");
        const sp = new URLSearchParams();
        if (fromid) {
          sp.set("from", fromid);
        }
        if (innerfromid) {
          sp.set("innerfrom", innerfromid);
        }
        if (sp.size > 0) {
          pam = sp;
        }
      } else if (item.url.indexOf("m.taocheche.com/apps/") > -1) {
        pam = "pfrom_id=43";
      }
      const linkStr = item.url.indexOf("?") > -1 ? "&" : "?";
      window.location.href = item.url + linkStr + pam;
      // let toUrl = item.url;
      // // 如果跳转的地址是相同域名的话，则截取出要跳转的路径+参数
      // const len = toUrl.indexOf(window.location.host)
      // if (len > -1) {
      //   const reg = new RegExp(`http(s)?\:\/\/${window.location.host}`)
      //   toUrl = toUrl.replace(reg, '')
      //   router.push(toUrl);
      // } else {
      //   window.location.href = toUrl
      // }
    } else {
      router.push(item.url + getPam(item.url));
    }
  };

  //渲染糖豆
  const sugarDom = () => {
    if (sugarBeans && sugarBeans.length > 0) {
      return (
        <div className={s.sugarclass}>
          {sugarBeans.map((item, idx) => {
            return (
              <div
                key={`sugar${idx}`}
                className={s.sugarclassdiv}
                onClick={() => {
                  reportClick("a0040004300000200000", {
                    elmcntt: item.text,
                    cpos: idx,
                  });

                  goto("tangdou", item);
                }}
              >
                <Image
                  src={item.image}
                  alt=""
                  width={104}
                  height={104}
                  className={s.classIcon}
                ></Image>
                <div className={`${s.text01} ${s.mt12}`}>{item.text}</div>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };
  // 渲染过滤条件
  const filterDom = () => {
    if (shortCutFilter && shortCutFilter.length > 0) {
      return (
        <div className={s.filterclass}>
          {shortCutFilter.map((item, idx) => {
            return (
              <div
                key={`filter${idx}`}
                className={s.filterclassdiv}
                onClick={() => {
                  reportClick("a0040004300000300000", {
                    elmcntt: item.text,
                    cpos: idx,
                  });

                  goto("filter", item);
                }}
              >
                <div className={`${s.text01} ${s.mt17}`}>{item.text}</div>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };
  // 渲染品牌
  const brandDom = () => {
    if (mainBrandList && mainBrandList.length > 0) {
      return (
        <div className={s.brandclass}>
          {mainBrandList.map((item, idx) => {
            return (
              <div
                key={`brand${idx}`}
                className={s.brandclassdiv}
                onClick={() => {
                  reportClick("a0040004300000400000", {
                    elmcntt: item.mainBrandName,
                    cpos: idx,
                  });
                  goto("pinpai", item);
                }}
              >
                <Image
                  src={item.coverUrl}
                  alt=""
                  width={104}
                  height={104}
                  className={s.classIcon2}
                ></Image>
                <div className={`${s.text01} ${s.mt12}`}>
                  {item.mainBrandName}
                </div>
              </div>
            );
          })}
          <div
            key={`brand555`}
            className={s.brandclassdiv}
            onClick={() => {
              reportClick("a0040004300000400000", {
                elmcntt: "更多",
                cpos: "4",
              });

              goto("pinpai", "more");
            }}
          >
            <Image
              src={more}
              alt=""
              width={104}
              height={104}
              className={s.classIcon2}
            ></Image>
            <div className={`${s.text01} ${s.mt12}`}>更多</div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={s.PinPaiDom}>
      {sugarDom()}
      {filterDom()}
      {brandDom()}
    </div>
  );
};

export default PinPaiDom;
