"use client";

import Recommended from "@/components/Recommended";
import useReport from "@/hooks/useReport";

const Main = (props) => {
  const { initList } = props;
  const { reportClick } = useReport();

  return (
    <>
      <Recommended
        pfrom_id="43"
        initList={initList}
        source={1}
        isFetch
        //车源点击
        onClick={(item) => {
          reportClick("a0040004300001000000", {
            ucar_id: item.ucarId,
            cops: item.index,
            //  tc_extendvalue1: `${ucarId}、${
            //   item.serialId
            // }、曝光类型：${sourceToString(item?.source)}`
          });
        }}
        //收藏点击
        onCollectStarClick={(item) => {
          reportClick("a0040004300001000001", {
            ucar_id: item.ucarId,
            cops: item.index,
            tc_extendvalue1: +item.isRecommend === 1 ? "取消收藏" : "收藏",
          });
        }}
        //车系点击
        onSeriesClick={(item) => {
          reportClick("a0040004300001000002", {
            cops: item.index,
            elmcntt: item.serialName,
            tc_extendvalue1: item.serialId,
          });
        }}
        // 更多
        onMoreClick={() => {
          reportClick("a0040004300001000003");
        }}
      />
    </>
  );
};

export default Main;
