"use client";
import Image from "next/image";
import Link from "@/components/Link";
import useRouter from "@/hooks/useRouter";
import useReport from "@/hooks/useReport";
import s from "./StoreDom.module.css";
import arrowright from "@/images/arrowright.png";
import dingwei from "@/images/dingwei.png";
import { app_host, self_host } from "@/utils/fetch";

const StroeDom = (props) => {
  const router = useRouter();
  const { reportClick } = useReport();
  const { homeData, cityData } = props;
  const { storeEntry } = homeData;

  const goto = () => {
    reportClick("a0040004300001200001"); // 埋点

    // router.push(
    window.location.href = `${app_host}/store/index.html?returnPath=${encodeURIComponent(
      `${self_host}/`
    )}&callBackField=city&city=${cityData?.citySpell}&storeId=${
      storeEntry?.storeId
    }&cityName=${storeEntry?.cityName}&cityId=${storeEntry?.cityId}&cityCode=${
      storeEntry?.cityCode
    }&longitude=${storeEntry?.longitude}&latitude=${
      storeEntry?.latitude
    }&pfrom_id=43`;
    // );
  };

  return (
    <div
      onClick={() => {
        goto();
      }}
    >
      <div className={s.storeDom}>
        <div className={s.storeLeft}>
          <div className={s.text01}>全国直营</div>
          <div className={s.text02}>
            {homeData?.storeEntry?.storeCnt || 0}家门店
          </div>
        </div>
        <div className={s.storeRight}>
          <div className={s.right01}>
            <div className={s.right02}>
              <div className={s.text03}>
                {homeData?.storeEntry?.storeName || ""}
              </div>
              <div className={s.right05}>
                <Image
                  src={arrowright}
                  alt=""
                  className={s.cityNameIcon}
                ></Image>
              </div>
            </div>
            {homeData?.storeEntry?.distance &&
            homeData?.storeEntry?.distance !== "附近城市" ? (
              <div className={s.text05}>{homeData?.storeEntry?.distance}</div>
            ) : null}
            {homeData?.storeEntry?.distance === "附近城市" ? (
              <div className={s.fujin}>
                <div className={s.text06}>{homeData?.storeEntry?.distance}</div>
              </div>
            ) : null}
          </div>
          <div className={s.right06}>
            <Image src={dingwei} alt="" className={s.cityNameIcon2}></Image>
            <div className={s.text04}>
              {homeData?.storeEntry?.address || "暂无"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StroeDom;
