import qs from "qs";
import fetch from "@/utils/fetch";
import { getPublicParams } from "@/utils/utils";

// 获取当前所在城市信息-根据ip
export function getiplocation() {
  return fetch("/c-city-consumer/city/getiplocation", {
    needCode: true,
  });
}

// 获取当前所在城市信息-根据经纬度
export function getcityinfobymapinfo(params) {
  return fetch(
    `/c-city-consumer/city/getcityinfobymapinfo?${qs.stringify(params)}`,
    {
      needCode: true,
    }
  );
}
// 获取当前所在城市信息-根据城市的pinyin
// spell=beijing
export function getCityOrPoiBySpell(params) {
  return fetch(
    `/c-city-consumer/city/getCityOrPoiBySpell?${qs.stringify(params)}`,
    {
      needCode: true,
    }
  );
}

// 广告位
export const getFocus = (params) =>
  fetch(`/c-activity-consumer/ad/focus?${qs.stringify(params)}`);

// 定位最近店铺
// cityId = 123;
export const getmendianshopinfo = (params) => {
  return fetch(`/c-shop-consumer/shopinfo/getmendianshopinfo`, {
    params,
    needCode: true,
  });
};

// 获取首页的数据
export const getHomeInfo = (params) => {
  return fetch(`/c-shop-consumer/shopinfo/v2/home?${qs.stringify(params)}`, {
    needCode: true,
  });
};
