"use client";

import Image from "next/image";
import useRouter from "@/hooks/useRouter";
import { useEffect, useState } from "react";
import s from "./SearchDom.module.css";
import fangdajing from "@/images/fangdajing.png";
import footprint from "@/utils/footprint";
import useReport from "@/hooks/useReport";
import Link from "@/components/Link";

const SearchDom = () => {
  const { reportClick } = useReport();
  const router = useRouter();
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    setDatas(footprint.get());
  }, []);

  const goto = () => {
    reportClick("a0040004300000000000"); // 埋点
    router.push("/search");
  };

  const goto2 = (item, idx) => {
    reportClick("a0040004300000500000", {
      elmcntt: footprint.getName(item),
      cpos: idx
    });
    router.push(footprint.getHref(item));
  };

  return (
    <div className={s.search02}>
      <div
        className={s.search03}
        id="sousuo002"
        onClick={() => {
          goto();
        }}
      >
        <div className={s.fangdajing}>
          <Image src={fangdajing} alt="" className={s.fangdajingImage}></Image>
        </div>
        <div className={s.search03text}>搜索品牌、型号或关键词</div>
      </div>
      {datas?.length > 0 ? (
        <div className={s.zuji}>
          <div className={s.zujititle}>足迹：</div>
          <div className={s.zujitext}>
            {datas.map((item, idx) => {
              return (
                <span
                  key={item?.sId || item.bId}
                  className={s.zujitext2}
                  onClick={() => {
                    goto2(item, idx);
                  }}
                >
                  {footprint.getName(item)}
                </span>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SearchDom;
