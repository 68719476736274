"use client";

// 首页左上角的城市 + 往下滚动出现的置顶搜索框体  涉及了经纬度定位+切换定位城市确认窗
import Image from "next/image";
import useRouter from "@/hooks/useRouter";
import Link from "@/components/Link";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import Cookies from "@/utils/Cookie";
import { getcityinfobymapinfo } from "@/api/home";
import { getOffset, getUrlParams } from "@/utils/utils";
import { addLoading, removeLoading } from "./indexLoading";
import useReport from "@/hooks/useReport";
import LocationToCity from "@/components/LocationToCity";

import s from "./GetCurrentCity.module.css";
import s2 from "./SearchDom.module.css";

import fangdajing02 from "@/images/fangdajing02.png";
import arrowBottom from "@/images/arrowbottom.png";

const GetCurrentCity = (props) => {
  const { getStoreData, cityData, changePositionCityLoadingAdd } = props;
  const { reportClick } = useReport();
  const router = useRouter();

  // const [loading, setLoading] = useState(true)
  const [opacity, setOpacity] = useState(0);
  const searchHeight = useRef([]);
  const [urlParams, setUrlParams] = useState({});

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      setUrlParams(getUrlParams()); // 需要在页面加载完毕后获取url， 如果放到外面，获取的是之前的url地址。。。
    }
  }, []);

  useEffect(() => {
    // 添加滚动事件监听器
    window.addEventListener("scroll", scrollFun);
    window.addEventListener("resize", searchHeightFun);
    searchHeightFun();

    // 移除滚动事件监听器
    return () => {
      window.removeEventListener("scroll", scrollFun);
      window.removeEventListener("resize", searchHeightFun);
    };
  }, []);

  //获取搜索框体的距上和自身高度
  const searchHeightFun = () => {
    if (!document.getElementById("sousuo002")) {
      setTimeout(() => {
        searchHeightFun();
      }, 100);
      return;
    }

    const aa = getOffset(document.getElementById("sousuo002"));
    const bb = [+aa.top - 80, +aa.top + +aa.height - 80, aa.height];
    searchHeight.current = bb;

    scrollFun();
  };

  //滚动时触发的函数，控制置顶框
  const scrollFun = () => {
    const hrr = searchHeight.current;
    if (hrr.length === 0) return;
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (scrollTop <= hrr[0]) {
      setOpacity(0);
    } else if (scrollTop >= hrr[1]) {
      setOpacity(1);
    } else {
      setOpacity((scrollTop - hrr[0]) / hrr[2]);
    }
  };

  const goto = (url) => {
    reportClick("a0040004300000000000"); // 埋点
    router.push(url);
  };

  const cityDom = () => {
    return (
      <Link
        href={`/selectDom/city?returnPath=${encodeURIComponent(
          "/"
        )}&callBackField=city&dels=storeId&city=${urlParams.city || ""}`}
      >
        <div className={s.cityNameParent}>
          <div className={s.cityName}>{cityData?.cityName}</div>
          <div className={s.cityNameIcon}>
            <Image src={arrowBottom} alt="" className={s.cityNameIcon}></Image>
          </div>
        </div>
      </Link>
    );
  };
  const searchDom = () => {
    return (
      <div className={s2.search04}>
        <div className={s2.fangdajing}>
          <Image
            src={fangdajing02}
            alt=""
            className={s2.fangdajingImage}
          ></Image>
        </div>
        <div
          className={s2.search03text}
          onClick={() => {
            goto("/search/");
          }}
        >
          搜索品牌、型号或关键词
        </div>
      </div>
    );
  };
  return (
    <div className={s.position02}>
      {cityDom()}
      <div
        className={s.fixclass}
        style={{ opacity: opacity, display: opacity === 0 ? "none" : "" }}
        id="topSearch002"
      >
        {cityDom()}
        {searchDom()}
      </div>
      <LocationToCity callback={getStoreData} changePositionCityLoadingAdd={changePositionCityLoadingAdd} />
    </div>
  );
};

export default GetCurrentCity;
