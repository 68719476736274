import { getLocal } from "@/utils/utils";

// 保存历史城市浏览数据
const historyFun = (o, newItem) => {
  if (+o.cityAreaId === +newItem.cityAreaId) return;
  if (o.cityCode && !o.cityAreaId) o.cityAreaId = o.cityCode;
  // console.log('historyCity=',o,newItem)

  const d2 = getLocal("historyCity");
  const hrr = d2 ? JSON.parse(d2) : [];
  const idx = hrr.findIndex((item) => {
    return +item.cityAreaId === +o.cityAreaId;
  });
  const idxNow = hrr.findIndex((item) => {
    return +item.cityAreaId === +newItem.cityAreaId;
  });
  // console.log('historyCity=hrr',hrr,idx,idxNow)

  const arr = [...hrr];
  const cobj = {
    cityId: o.cityId,
    cityAreaId: o.cityAreaId,
    cityName: o.cityName,
    citySpell: o.citySpell
  };

  if (idxNow >= 0) arr.splice(idxNow, 1);

  if (idx >= 0) {
    arr.splice(idx, 1);
    arr.unshift(cobj);
  } else {
    arr.unshift(cobj);
    if (arr.length >= 8) {
      arr.splice(8);
    }
  }
// console.log('historyCity',JSON.stringify(arr))
  localStorage.setItem("historyCity", JSON.stringify(arr));
};

export {
  historyFun
}